define([
  'knockout',
  'bootstrap'
], function (ko, bootstrap) {
  ko.bindingHandlers.tooltip = {
    init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
      var value = ko.unwrap(valueAccessor());
      var $element = $(element);

      $element.attr('data-bs-toggle', 'tooltip');
      $element.attr('data-bs-title', value['title']);
      $element.attr('data-bs-placement', value['placement']);
      $element.attr('data-bs-html', value['isHTML']);

      if (value['isHTML']) {
        new bootstrap.Tooltip($element, {
          template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner text-start"></div></div>'
        });
      } else {
        new bootstrap.Tooltip($element, {
          template: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>'
        });
      }
    },
    update: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
      // This will be called once when the binding is first applied to an element,
      // and again whenever any observables/computeds that are accessed change
      // Update the DOM element based on the supplied values here.
    }
  };

  return ko;
});