// js
require('@popperjs/core');
require('bootstrap');
require('./js/knockout.custom.bindings');

// css
require('animate.css/animate.min.css');
require('bootstrap-icons/font/bootstrap-icons.min.css');

// scss
require('./css/style.scss');

// assets
require('./images/logo.png')
require('./images/streamlined.svg')
require('./images/standard.svg')
require('./images/advanced.svg')

define(['knockout'], function (ko) {
  self.module = ko.observable();
  self.params = ko.observable();

  ko.components.register('fsu', require('./components/fsu'));

  self.loadModule = function (module, params) {
    self.module(module);
    self.params(params);
    $('html,body').animate({ scrollTop: 0 }, '1000');
  };

  self.loadModule('fsu', []);
  ko.applyBindings({}, document.getElementById('ko-binding'));
});
