const { observable } = require("knockout");

define([
  'knockout',
  'knockout.validation',
  'text-loader!./fsu.html',
  'moment',
], function (ko, validation, fsuTemplate, moment) {
  validation.registerExtenders();

  validation.init(
    {
      insertMessages: true,
      errorElementClass: 'is-invalid',
      errorMessageClass: 'invalid-feedback'
    },
    true
  );

  var fsuViewModel = function () {
    var self = this;

    self.currentStep = ko.observable(1);
    self.highestStep = ko.observable(1);
    self.payload = ko.observable(new payloadModel(self));
    self.isLoading = ko.observable(false);
    self.showStepperBar = ko.observable(false);
    self.draftId = ko.observable('');
    self.draftStatus = ko.observable('');
    self.validateHydrateError = ko.observable('');
    self.referrer = ko.observable('');

    self.draftStatus.subscribe(function() {
      if (self.draftId() != '') {
        if (self.draftStatus() == 'completed') {
          self.hydrateForm();
        }

        if (self.draftStatus() == 'cancelled') {
          self.changeStep(self.STEP_CANCELLED_FROM_STRIPE);
        }
      }
    });

    // individual steps
    self.STEP_START_PAGE = 1;
    self.STEP_WHO_IS_COMPLETING = 2;
    self.STEP_UNDERSTANDING_RESPONSIBILITIES = 3;
    self.STEP_IMPORTANT_INFO = 4;
    self.STEP_USING_ACCOUNTANT_ADVISER = 5;
    self.STEP_ACC_ADVISER_DETAILS = 6;
    self.STEP_DOC_DELIVERY = 7;
    self.STEP_HEFFRON_ADMIN = 8;
    self.STEP_HEFFRON_ADMIN_SERVICES = 9;
    self.STEP_GST_REGISTRATION = 10;
    self.STEP_MAILBOX_SERVICE = 11;
    self.STEP_DOC_OR_DOCATO = 12;
    self.STEP_TAX_AGENT_DETAILS = 13;
    self.STEP_CONFIRMATION = 14;
    self.STEP_FUND_INFORMATION = 15;
    self.STEP_TRUSTEE_STRUCTURE = 16;
    self.STEP_EXISTING_COMPANY_DETAILS = 17;
    self.STEP_NEW_COMPANY_DETAILS = 18;
    self.STEP_INDIVIDUAL_TABLE = 19;
    self.STEP_INDIVIDUAL = 20;
    self.STEP_REVIEW = 21;
    self.STEP_PRIVACY_POLICY = 22;
    self.STEP_THANK_YOU = 23;
    self.STEP_ERROR_VALIDATE_HYDRATE = 97;
    self.STEP_CANCELLED_FROM_STRIPE = 98;
    self.STEP_CANCEL_APPLICATION = 99;

    // step group boundaries
    self.STEP_1_LB = self.STEP_START_PAGE;
    self.STEP_1_UB = self.STEP_DOC_DELIVERY;
    self.STEP_2_LB = self.STEP_HEFFRON_ADMIN;
    self.STEP_2_UB = self.STEP_CONFIRMATION;
    self.STEP_3_LB = self.STEP_FUND_INFORMATION;
    self.STEP_3_UB = self.STEP_INDIVIDUAL;
    self.STEP_4_LB = self.STEP_REVIEW;
    self.STEP_4_UB = self.STEP_THANK_YOU;

    self.isDev = function () {
      return process.env.ENV !== 'prod';
    };

    self.currentlyInStepGroup1 = function () {
      return self.currentStep() >= self.STEP_1_LB && self.currentStep() <= self.STEP_1_UB;
    };

    self.currentlyInStepGroup2 = function () {
      return self.currentStep() >= self.STEP_2_LB && self.currentStep() <= self.STEP_2_UB;
    };

    self.currentlyInStepGroup3 = function () {
      return self.currentStep() >= self.STEP_3_LB && self.currentStep() <= self.STEP_3_UB;
    };

    self.currentlyInStepGroup4 = function () {
      return self.currentStep() >= self.STEP_4_LB && self.currentStep() <= self.STEP_4_UB;
    };

    self.canGoToStepGroup1 = function () {
      if (self.highestStep() > self.STEP_1_LB && self.currentlyInStepGroup1() === false) {
        return true;
      }

      return false;
    };

    self.canGoToStepGroup2 = function () {
      if (self.highestStep() >= self.STEP_2_LB && self.currentlyInStepGroup2() === false) {
        return true;
      }

      return false;
    };

    self.canGoToStepGroup3 = function () {
      if (self.highestStep() >= self.STEP_3_LB && self.currentlyInStepGroup3() === false) {
        return true;
      }

      return false;
    };

    self.canGoToStepGroup4 = function () {
      if (self.highestStep() >= self.STEP_4_LB && self.currentlyInStepGroup4() === false) {
        return true;
      }

      return false;
    };

    self.goToStepGroup1 = function() {
      if (self.canGoToStepGroup1()) {
        self.currentStep(self.STEP_1_LB);
      }
    }

    self.goToStepGroup2 = function() {
      if (self.canGoToStepGroup2()) {
        self.currentStep(self.STEP_2_LB);
      }
    }

    self.goToStepGroup3 = function() {
      if (self.canGoToStepGroup3()) {
        self.currentStep(self.STEP_3_LB);
      }
    }

    self.goToStepGroup4 = function() {
      if (self.canGoToStepGroup4()) {
        self.currentStep(self.STEP_4_LB);
      }
    }

    self.currentStep.subscribe(function(step) {
      if (step > self.highestStep() && step < self.STEP_CANCEL_APPLICATION) {
        self.highestStep(step);
      }
    });

    self.nextStep = function () {
      $('html,body').animate({ scrollTop: 0 }, '1000');
      
      if (self.currentStep() == self.STEP_WHO_IS_COMPLETING && self.payload().whoIsCompletingForm() == 'Adviser/Accountant') {
        self.currentStep(self.STEP_ACC_ADVISER_DETAILS);
        return;
      }

      if (self.currentStep() == self.STEP_DOC_DELIVERY && self.payload().whoIsCompletingForm() == 'Trustee') {
        // Suppress Service Selection pages other than Admin Package & GST (i.e. Heffron SMSF Admin Service, Document Services, Tax agent details, Confirmation) as all direct clients will now be admin clients and the docs only service will no longer be offered to direct clients.
        self.currentStep(self.STEP_HEFFRON_ADMIN_SERVICES);
        return;
      }

      if (self.currentStep() == self.STEP_USING_ACCOUNTANT_ADVISER && self.payload().usingAdviserServices() == false) {
        self.currentStep(self.STEP_DOC_DELIVERY);
        return;
      }

      if (self.currentStep() == self.STEP_HEFFRON_ADMIN && self.payload().heffronAdministration() == false) {
        self.currentStep(self.STEP_DOC_OR_DOCATO);
        return;
      }

      if (self.currentStep() == self.STEP_GST_REGISTRATION && (self.payload().gstRegistration().registerForGst() == 'Yes' || self.payload().gstRegistration().voluntarilyRegisterForGst() != '')) {
        self.currentStep(self.STEP_MAILBOX_SERVICE);
        return;
      }

      if ((self.currentStep() == self.STEP_GST_REGISTRATION || self.currentStep() == self.STEP_MAILBOX_SERVICE) && self.payload().heffronAdministration() == true) {
        self.currentStep(self.STEP_FUND_INFORMATION);
        return;
      }

      if (self.currentStep() == self.STEP_DOC_OR_DOCATO && self.payload().service() == 'Documentation') {
        self.currentStep(self.STEP_CONFIRMATION);
        return;
      }

      if (self.currentStep() == self.STEP_TRUSTEE_STRUCTURE) {
        if (self.payload().fund().trusteeType() === 'Individual') {
          if (self.payload().fund().individuals().length > 0) {
            self.currentStep(self.STEP_INDIVIDUAL_TABLE);
            return;
          }
          
          self.payload().fund().individual(new individualModel(self.payload().heffronAdministration));
          self.currentStep(self.STEP_INDIVIDUAL);
          return;
        }
  
        if (self.payload().fund().trusteeType() === 'CorpExisting') {
          self.currentStep(self.STEP_EXISTING_COMPANY_DETAILS);
          return;
        }
  
        if (self.payload().fund().trusteeType() === 'CorpNonExisting') {
          //self.payload().fund().companyDetails().heffronIsAsicAndRegisteredOffice('No');
          self.payload().fund().companyDetails().heffronIsAsicAndRegisteredOffice.valueHasMutated();
          self.currentStep(self.STEP_NEW_COMPANY_DETAILS);
          return;
        }
      }

      if (self.currentStep() == self.STEP_EXISTING_COMPANY_DETAILS) {
        if (self.payload().fund().individuals().length > 0) {
          self.currentStep(self.STEP_INDIVIDUAL_TABLE);
        } else {
          self.payload().fund().individual(new individualModel(self.payload().heffronAdministration));
          self.currentStep(self.STEP_INDIVIDUAL);
        }
        return;
      }

      if (self.currentStep() == self.STEP_NEW_COMPANY_DETAILS) {
        if (self.payload().fund().individuals().length > 0) {
          self.currentStep(self.STEP_INDIVIDUAL_TABLE);
        } else {
          self.payload().fund().individual(new individualModel(self.payload().heffronAdministration));
          self.currentStep(self.STEP_INDIVIDUAL);
        }
        return;
      }

      if (self.currentStep() == self.STEP_INDIVIDUAL_TABLE) {
        self.currentStep(self.STEP_REVIEW);
        return;
      }

      self.currentStep(self.currentStep() + 1);
    };

    self.prevStep = function () {
      $('html,body').animate({ scrollTop: 0 }, '1000');

      if (self.currentStep() == self.STEP_ACC_ADVISER_DETAILS && self.payload().whoIsCompletingForm() == 'Adviser/Accountant') {
        self.currentStep(self.STEP_WHO_IS_COMPLETING);
        return;
      }

      if (self.currentStep() == self.STEP_HEFFRON_ADMIN_SERVICES && self.payload().whoIsCompletingForm() == 'Trustee') {
        self.currentStep(self.STEP_DOC_DELIVERY);
        return;
      }

      if (self.currentStep() == self.STEP_DOC_DELIVERY && self.payload().usingAdviserServices() == false && self.payload().whoIsCompletingForm() == 'Trustee') {
        self.currentStep(self.STEP_USING_ACCOUNTANT_ADVISER);
        return;
      }

      if (self.currentStep() == self.STEP_DOC_OR_DOCATO && self.payload().heffronAdministration() == false) {
        self.currentStep(self.STEP_HEFFRON_ADMIN);
        return;
      }

      if (self.currentStep() == self.STEP_CONFIRMATION && self.payload().service() == 'Documentation') {
        self.currentStep(self.STEP_GST_REGISTRATION);
        return;
      }

      if (self.currentStep() == self.STEP_FUND_INFORMATION && self.payload().heffronAdministration() == true) {
        self.currentStep(self.STEP_GST_REGISTRATION);
        return;
      }

      if (self.currentStep() == self.STEP_EXISTING_COMPANY_DETAILS || self.currentStep() == self.STEP_NEW_COMPANY_DETAILS || self.currentStep() == self.STEP_INDIVIDUAL_TABLE) {
        self.currentStep(self.STEP_TRUSTEE_STRUCTURE);
        return;
      }

      if (self.currentStep() == self.STEP_REVIEW) {
        self.currentStep(self.STEP_INDIVIDUAL_TABLE);
        return;
      }

      self.currentStep(self.currentStep() - 1);
    };

    self.changeStep = function (step) {
      $('html,body').animate({ scrollTop: 0 }, '1000');
      self.currentStep(step);
    } 

    self.canChangeStep = function () {
      if (self.currentStep() == self.STEP_WHO_IS_COMPLETING && self.payload().whoIsCompletingForm() == '') {
        return false;
      }

      if (self.currentStep() == self.STEP_DOC_OR_DOCATO && self.payload().service() == '') {
        return false;
      }

      if (self.currentStep() == self.STEP_FUND_INFORMATION && self.payload().fund().name() == '') {
        return false;
      }

      return true;
    }

    self.validateForm = function () {
      self.isLoading(true);
      self.validateHydrateError('');

      $.ajax({
        type: 'POST',
        url: '/helix/fund/validate',
        contentType: 'application/json',
        data: ko.toJSON({ data: { source: 'fsu', draftId: self.draftId(), payload: self.payload(), redirect: window.location }}),
        success: async function (response) {
          if (response['data'].hasOwnProperty('sessionUrl')) {
            // directs
            window.location.href = response['data']['sessionUrl'];
          } else {
            // non directs
            self.draftId(response['data']['draftId']);
            self.draftStatus('completed');
          }
        },
        error: async function (response) {
          self.validateHydrateError(response.responseJSON.message);
          self.changeStep(self.STEP_ERROR_VALIDATE_HYDRATE);
        },
        complete: function (response) {
          self.isLoading(false);
        },
      });
    };

    self.hydrateForm = function () {
      self.isLoading(true);
      self.validateHydrateError('');

      $.ajax({
        type: 'POST',
        url: '/helix/fund/hydrate',
        contentType: 'application/json',
        data: ko.toJSON({ data: { source: 'fsu', draftId: self.draftId() }}),
        success: async function (response) {
          self.changeStep(self.STEP_THANK_YOU);
        },
        error: async function (response) {
          self.validateHydrateError(response.responseJSON.message);
          self.changeStep(self.STEP_ERROR_VALIDATE_HYDRATE);
        },
        complete: function (response) {
          self.isLoading(false);
        },
      });
    }

    var searchParams = new URLSearchParams(window.location.search);
    self.draftId(searchParams.get('token'));
    self.draftStatus(searchParams.get('status'));
    self.referrer(searchParams.get('ref'));
  };

  var payloadModel = function (parent) {
    var self = this;

    self.whoIsCompletingForm = ko.observable('') // trustee or adviser/accountant
    self.usingAdviserServices = ko.observable(false);
    self.adviserDetails = ko.observable(new adviserDetailsModel());
    self.documentationTransport = ko.observable(new documentationTransportModel());
    self.heffronAdministration = ko.observable(false);
    self.gstRegistration = ko.observable(new gstRegistrationModel());
    self.heffronAdministrationServices = ko.observable(new heffronAdministrationServicesModel());
    self.service = ko.observable(''); // Documentation or DocumentationAto
    self.taxAgentDetails = ko.observable(new taxAgentDetailsModel());
    self.fund = ko.observable(new fundModel(self));
    //self.signee = ko.observable('');
    //self.signeeIsAuthorised = ko.observable(false);
    self.termsAndConditions = ko.observable(new termsAndConditionsModel());
    self.heffronAsMailingAddress = ko.observable(false);
    self.referrer = ko.observable('');

    self.whoIsCompletingForm.subscribe(function(val) {
      if (val === 'Trustee') {
        self.heffronAdministration(true);
        self.documentationTransport().how('Post');
        self.documentationTransport().emailOne('');
        self.documentationTransport().emailTwo('');
        self.service('DocumentationAto');
      } else {

      }
    });

    self.heffronAdministration.subscribe(function(val){
      if (val == true) {
        self.taxAgentDetails().firmName('Heffron Taxation Pty Ltd');
        self.taxAgentDetails().contactPerson('Andrea Connor');
        self.taxAgentDetails().role('Australian Tax Agent');
        self.taxAgentDetails().phoneNumber('0249302100');
        self.taxAgentDetails().emailAddress('heffron@heffron.com.au');
        self.taxAgentDetails().esa('smsfdataflow');
      } else {
        self.taxAgentDetails().firmName('');
        self.taxAgentDetails().contactPerson('');
        self.taxAgentDetails().role('');
        self.taxAgentDetails().phoneNumber('');
        self.taxAgentDetails().emailAddress('');
        self.taxAgentDetails().esa('');
      }
    });

    self.isUsingAdviserServices = function(val) {
      self.usingAdviserServices(val);
      parent.nextStep();
    }

    self.isHeffronAdministration = function(val) {
      self.heffronAdministration(val);

      if (val == true && self.fund().companyDetails().hasOwnProperty('registeredOfficeAddress')) {
        // set default registered address for new company if applicable
        self.fund().companyDetails().setDefaultRegisteredOfficeAddress();
      } else if (self.fund().companyDetails().hasOwnProperty('registeredOfficeAddress')) {
        self.fund().companyDetails().registeredOfficeAddress().clear();
      }

      parent.nextStep();
    }

    self.isHeffronAsMailingAddress = function(val) {
      self.heffronAsMailingAddress(val);
      parent.nextStep();
    }

    self.changeStep = function (step) {
      parent.changeStep(step);
    }

    var searchParams = new URLSearchParams(window.location.search);
    self.referrer(searchParams.get('ref'));
  }

  var existingCompanyDetailsModel = function () {
    var self = this;

    self.name = ko.observable('').extend({ required: true });
    self.acn = ko.observable('').extend({ required: true, number: true, minLength: 9, maxLength: 9 });
    self.registeredOfficeAddress = ko.observable(new addressModel());
    self.validation = ko.validation.group(self, { deep: true });

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var newCompanyDetailsModel = function (isHeffronAdministration) {
    var self = this;

    self.name = ko.observable('').extend({ required: true });
    self.heffronIsAsicAndRegisteredOffice = ko.observable('Yes').extend({ required: { onlyIf: function() { return isHeffronAdministration() } }});
    self.registeredOfficeAddress = ko.observable(new addressModel());
    self.principalPlaceOfBusinessAddress = ko.observable(new addressModel());
    self.principalPlaceSameAsRegistered = ko.observable(false);
    //self.numberOfShares = ko.observable().extend({ required: true, number: true, min: 1 });
    self.validation = ko.validation.group(self, { deep: true });

    self.setDefaultRegisteredOfficeAddress = function() {
      self.registeredOfficeAddress().streetAddressOne('C/O Heffron, 1/27-39 Bulwer St');
      self.registeredOfficeAddress().suburb('Maitland');
      self.registeredOfficeAddress().state('NSW');
      self.registeredOfficeAddress().postcode('2324');
    }

    self.heffronIsAsicAndRegisteredOffice.subscribe(function(val) {
      if (val == 'Yes' && isHeffronAdministration() == true) {
        self.setDefaultRegisteredOfficeAddress();
      } else {
        self.registeredOfficeAddress(new addressModel());
      }

      self.validation = ko.validation.group(self, { deep: true });
    });

    self.principalPlaceSameAsRegistered.subscribe(function(val) {
      if (val == true) {
        self.principalPlaceOfBusinessAddress(self.registeredOfficeAddress());
      } else {
        self.principalPlaceOfBusinessAddress(new addressModel());
      }

      self.validation = ko.validation.group(self, { deep: true });
    });

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var fundModel = function (parent) {
    var self = this;

    self.name = ko.observable('');
    self.isCorporateTrustee = ko.observable(true);
    self.isExistingCorporateTrustee = ko.observable(false);
    self.trusteeType = ko.observable('CorpNonExisting'); // individual, corp existing or corp non-existing
    self.companyDetails = ko.observable(new newCompanyDetailsModel(parent.heffronAdministration)); // existingCompanyDetailsModel or newCompanyDetailsModel
    self.heffronIsAsicAndRegisteredOffice = ko.observable('');
    self.individual = ko.observable();
    self.individuals = ko.observableArray([]); // up to 6
    self.individualErrors = ko.observableArray([]);
    self.markedForDelete = ko.observable();

    self.isCorporateTrustee.subscribe(function(isCorpTee) {
      if (!isCorpTee) {
        self.trusteeType('Individual');
      } else {
        // default to 'special purpose'
        self.trusteeType('CorpNonExisting');
      }
    });

    self.isExistingCorporateTrustee.subscribe(function(isExisting) {
      if (!isExisting) {
        self.trusteeType('CorpNonExisting');
      } else {
        self.trusteeType('CorpExisting');
      }
    });

    self.trusteeType.subscribe(function(type) {
      if (type === 'Individual') {
        self.companyDetails({});
      }

      if (type === 'CorpExisting') {
        self.companyDetails(new existingCompanyDetailsModel());
      }

      if (type === 'CorpNonExisting') {
        self.companyDetails(new newCompanyDetailsModel(parent.heffronAdministration));
      }
    });

    self.saveIndividual = function () {
      // need to check that the individual isn't already in the array
      for (let i = 0; i < self.individuals().length; i++) {
        var existing = self.individuals()[i];
        if (existing.tfn() == self.individual().tfn()) {
          self.individuals.splice(i, 1);
          break;
        }
      }

      self.individuals().push(self.individual());

      parent.changeStep(19);
    };

    self.cancelIndividual = function () {
      // add individual back into array as they are removed on edit
      self.individuals().push(self.individual());

      self.individual(new individualModel(parent.heffronAdministration));

      parent.changeStep(19);
    }

    self.editIndividual = function (individual) {
      self.individual(individual);

      self.individuals.splice(self.individuals.indexOf(individual), 1);
      self.individualErrors([]);

      parent.changeStep(20);
    };

    self.deleteIndividual = function (individual) {
      self.individuals.splice(self.individuals.indexOf(individual), 1);
      
      self.individual(new individualModel(parent.heffronAdministration));

      self.markedForDelete(null);
    };

    self.addIndividual = function () {
      self.individual(new individualModel(parent.heffronAdministration));

      parent.changeStep(20);
    };

    self.individualsAreValid = function () {
      self.individualErrors([]);

      var memberCount = 0;
      ko.utils.arrayForEach(self.individuals(), function (individual) {
        if (individual.isMember()) {
          memberCount++;
        }
      });

      // for individual and corp tee
      if (memberCount >= 2) {
        // in this instance, all trustees/directors must be members
        ko.utils.arrayForEach(self.individuals(), function (individual) {
          if (!individual.isMember() && (individual.isIndividualTrustee() || individual.isDirector())) {
            self.individualErrors.push('All trustees/directors must be members.');
          }
        });
      }

      // for corp tee
      if (self.trusteeType() == 'CorpNonExisting' || self.trusteeType() == 'CorpExisting') {
        // at least 1 individual needs to be a member, and one needs to be a director
        var atleastOneMember = false;
        var atleastOneDirector = false;

        ko.utils.arrayForEach(self.individuals(), function (individual) {
          if (individual.isMember()) {
            atleastOneMember = true;
          }
          if (individual.isDirector()) {
            atleastOneDirector = true;
          }
        });
        if (atleastOneMember == false) {
          self.individualErrors.push('At least one person needs to be a member.');
        }
        if (atleastOneDirector == false) {
          self.individualErrors.push('At least one person needs to be a director.');
        }
      }

      // for individual
      if (self.trusteeType() == 'Individual') {
        if (self.individuals().length < 2) {
          self.individualErrors.push('There must be at least 2 members.');
        }
        if (self.individuals().length > 6) {
          self.individualErrors.push('There can be no more than 6 members.');
        }
      }

      if (self.individualErrors().length > 0) {
        return false;
      }
      
      return true;
    }
  }

  var taxAgentDetailsModel = function () {
    var self = this;

    self.firmName = ko.observable('').extend({ required: true });
    self.contactPerson = ko.observable('').extend({ required: true });
    self.role = ko.observable('').extend({ required: true });
    self.regNo = ko.observable('').extend({ required: true });
    self.phoneNumber = ko.observable('').extend({ required: true, number: true, minLength: 10, maxLength: 10 });
    self.emailAddress = ko.observable('').extend({ required: true, email: true });
    self.address = ko.observable(new addressModel());
    self.esa = ko.observable('').extend({ required: true });
    self.taxConfirmation = ko.observable(false);
    self.validation = ko.validation.group(self, { deep: true });

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var gstRegistrationModel = function () {
    var self = this;

    self.registerForGst = ko.observable('').extend({ required: true });
    self.voluntarilyRegisterForGst = ko.observable('').extend({ required: { onlyIf: function() { return self.registerForGst() == 'No' }}});
    self.whenToPayGst = ko.observable('').extend({ required: { onlyIf: function() { return self.voluntarilyRegisterForGst() == 'Yes' }}}); // quarterly or annually
    self.investInCommercialProperty = ko.observable('').extend({ required: { onlyIf: function() { return self.registerForGst() == 'Yes' || self.voluntarilyRegisterForGst() == 'Yes' }}});
    self.validation = ko.validation.group(self, { deep: true });

    self.registerForGst.subscribe(function() {
      self.voluntarilyRegisterForGst('');
    });

    self.voluntarilyRegisterForGst.subscribe(function() {
      self.whenToPayGst('');
    });

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var heffronAdministrationServicesModel = function () {
    var self = this;

    self.package = ko.observable('Streamlined'); // streamlined, standard or advanced
  }

  var documentationTransportModel = function () {
    var self = this;

    self.how = ko.observable('Post').extend({ required: true }); // Post or Email
    self.emailOne = ko.observable('').extend({ required: { onlyIf: function() { return self.how() == 'Email' }}, email: true});
    self.emailTwo = ko.observable('').extend({ email: true});
    self.address = ko.observable(new addressModel());
    self.validation = ko.validation.group(self, { deep: true });

    self.how.subscribe(function() {
      self.address().streetAddressOne('');
      self.address().streetAddressTwo('');
      self.address().suburb('');
      self.address().state('');
      self.address().postcode('');
      self.emailOne('');
      self.emailTwo('');

      if (self.how() == 'Post') {
        self.validation = ko.validation.group(self, { deep: true });
      } else {
        self.validation = ko.validation.group(self);
      }
    });

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var adviserDetailsModel = function () {
    var self = this;

    self.firmName = ko.observable('').extend({ required: true });
    self.contactPerson = ko.observable('').extend({ required: true });
    self.role = ko.observable('').extend({ required: true });
    self.phoneNumber = ko.observable('').extend({ required: true, number: true, minLength: 10, maxLength: 10 });
    self.adviserIsMainContact = ko.observable("true"); // if not true, then trustee is main contact - "false"
    self.validation = ko.validation.group(self);

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  var addressModel = function () {
    var self = this;

    self.streetAddressOne = ko
      .observable('')
      .extend({
        required: {
          params: true,
          message: 'Street address is a required field',
        },
      });
    self.streetAddressTwo = ko.observable('');
    self.suburb = ko
      .observable('')
      .extend({
        required: { params: true, message: 'Suburb is a required field' },
      });
    self.state = ko
      .observable('')
      .extend({
        required: { params: true, message: 'State is a required field' },
      });
    self.postcode = ko
      .observable('')
      .extend({
        required: { params: true, message: 'Postcode is a required field' },
        number: true,
        minLength: 4,
        maxLength: 4
      });

    self.occupier = ko.observable('');

    self.fullAddressLine = function () {
      var fullAddress = self.streetAddressOne() + ' ' + self.streetAddressTwo() + ' ' + self.suburb() + ' ' + self.state() + ' ' + self.postcode();
      return fullAddress.replaceAll("  ", " ");
    };

    self.clear = function() {
      self.streetAddressOne('');
      self.streetAddressTwo('');
      self.suburb('');
      self.state('');
      self.postcode('');
    }
  };

  var individualModel = function (isHeffronAdministration) {
    var self = this;

    // standard fields
    self.title = ko.observable('').extend({ required: true });
    self.firstName = ko.observable('').extend({ required: true });
    self.noMiddleName = ko.observable(false);
    self.middleName = ko.observable('').extend({ required: { onlyIf: function() { return self.noMiddleName() == false } }});
    self.lastName = ko.observable('').extend({ required: true });
    self.gender = ko.observable('').extend({ required: true }); // m, f or other
    self.dob = ko.observable('').extend({ required: true });
    self.age = ko.observable('').extend({ required: true });
    self.tfn = ko.observable('').extend({ required: true, number: true, minLength: 8, maxLength: 9 });
    self.residentialAddress = ko.observable(new addressModel());

    self.dob.subscribe(function() {
      
      self.age(
        new Date().getMonth() < new Date(self.dob()).getMonth() - 1 ? 
          new Date().getFullYear() - new Date(self.dob()).getFullYear() - 1 :
          new Date().getFullYear() - new Date(self.dob()).getFullYear()
      );
    });

    self.dobFmt = ko.pureComputed(function () {
      return moment(self.dob()).format('DD/MM/YYYY');
    });

    // if choosing heffron admin
    self.postalSameAsResidential = ko.observable(false);
    self.postalAddress = ko.observable(new addressModel());
    self.emailAddress = ko.observable('').extend({ required: { onlyIf: function() { return isHeffronAdministration() == true }, email: true}});
    self.mobileNumber = ko.observable('').extend({ required: { onlyIf: function() { return isHeffronAdministration() == true }, number: true, minLength: 10, maxLength: 10 }});

    // roles
    self.isMember = ko.observable(false);
    self.isIndividualTrustee = ko.observable(false);
    self.isDirector = ko.observable(false);
    self.isShareholder = ko.observable(false);
    self.isSecretary = ko.observable(false);

    // director fields
    self.directorId = ko.observable().extend({ required: { onlyIf: function() { return self.isDirector() == true }}, number: true, minLength: 15 });
    self.placeOfBirth = ko.observable('');
    self.numberOfShares = ko.observable().extend({ required: { onlyIf: function() { return self.isDirector() == true }}, number: true, min: 1 });

    self.validation = ko.validation.group(self, { deep: true });
    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }

    self.postalSameAsResidential.subscribe(function(val) {
      if (val) {
        self.postalAddress().streetAddressOne(self.residentialAddress().streetAddressOne());
        self.postalAddress().streetAddressTwo(self.residentialAddress().streetAddressTwo());
        self.postalAddress().suburb(self.residentialAddress().suburb());
        self.postalAddress().state(self.residentialAddress().state());
        self.postalAddress().postcode(self.residentialAddress().postcode());
      } else {
        self.postalAddress = ko.observable(new addressModel());
      }

      self.validation = ko.validation.group(self, { deep: true });
    });

    self.fullName = function () {
      var fullName = self.title() + ' ' + self.firstName() + ' ' + (self.noMiddleName() ? '' : self.middleName()) + ' ' + self.lastName();
      return fullName.replaceAll("  ", " ");
    };

    self.isMember.subscribe(function(isMember) {
      if (isMember) {
        self.numberOfShares();
      }
    });

    if (isHeffronAdministration() == false) {
      // default postal address to circumvent validation
      self.postalAddress().streetAddressOne('null');
      self.postalAddress().streetAddressTwo('');
      self.postalAddress().suburb('null');
      self.postalAddress().state('null');
      self.postalAddress().postcode('0000');
    }
  };

  var termsAndConditionsModel = function () {
    var self = this;

    self.agreeToPrivacyPolicy = ko.observable(false).extend({ equal: { params: true, message: 'You must agree before continuing' } });
    self.agreeToServiceAndPaymentTerms = ko.observable(false).extend({ equal: { params: true, message: 'You must agree before continuing' } });
    self.validation = ko.validation.group(self);

    self.isValid = function() {
      self.validation.showAllMessages();

      if (self.validation.isAnyMessageShown()) {
        return false;
      }

      return true;
    }
  }

  return { viewModel: fsuViewModel, template: fsuTemplate };
});
